import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact'
import { Link } from 'gatsby'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/heroSubpage'
import FadeInWhenVisible from '../../../components/fadeInWhenVisible'
import ColumnAlternating from '../../../components/columnAlternating'
import Card from '../../../components/card'
import Cta from '../../../components/cta'

const ServerBranding = ({ data }) => {
  const post = data.markdownRemark
  const brand = data.serverbrands.edges 
  const resources = data.resources

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}             
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <FadeInWhenVisible>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div className="mt-3 richtext divlink" dangerouslySetInnerHTML={{ __html: post.html }} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </FadeInWhenVisible>
        </section>

        {brand && (
          <section className="bg-white-grey-gradient">
            <FadeInWhenVisible>
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-3">
                  Try out our server design sample tool
                </h2>
                <div className="pb-5 font-w-400 text-medium">
                  Choose from the following server appliance brands
                </div>
                <MDBRow>
                  {brand.map(({ node }, index) => (
                    <MDBCol md={index < 3 ? "4" : "6"} className="py-3" key={index}>
                      <MDBCard>
                        <div style={index < 3 ? { minHeight: '520px',maxHeight: '520px' } : { minHeight: '315px',maxHeight: '315px' }} >
                          <MDBCardTitle tag="div" className="text-center title-extra-small font-w-700 pt-3" style={{minHeight: "60px", maxHeight: "60px"}}>
                            <GatsbyImage
                              image={node.frontmatter.brandlogo.childImageSharp.gatsbyImageData}
                              className="m-3"
                              alt={node.frontmatter.alttext}
                            />
                          </MDBCardTitle>
                          <MDBCardBody>
                            <div
                              className="text-card-small font-w-400"
                              style={ index < 3 ? { minHeight: '175px', maxHeight: '175px' } : { minHeight: '105px', maxHeight: '105px' } }
                            >
                              {node.frontmatter.branddescription}
                            </div>
                            <hr />
                            <MDBRow>
                              {node.frontmatter.section.map((sections, index) => (
                                <MDBCol className="px-2" key={index}>
                                  <Link
                                    to={sections.link}
                                    className="nav-link btn btn-mdb-color mr-0 mt-xl-3"
                                    style={{ backgroundColor: 'rgb(41,83,149)', color: '#fff', width: '100%' }}
                                    target="_blank"
                                  >
                                    {sections.linktext}
                                  </Link>
                                </MDBCol>
                              ))}
                            </MDBRow>
                          </MDBCardBody>
                        </div>
                      </MDBCard>
                    </MDBCol>
                  ))}
                </MDBRow>
              </MDBContainer>
            </FadeInWhenVisible>
          </section>
        )}

        {post.frontmatter.section && (
          <>
            {post.frontmatter.section.map((sections, index) => (
              <section className={index % 2 === 0 ? 'bg-white' : 'bg-white-grey-gradient'} key={index}>
                <FadeInWhenVisible>
                  <MDBContainer>
                    <div className="about-overview">
                      <ColumnAlternating
                        key={index}
                        textCol="7"
                        imageCol="5"
                        headingLevel={2}
                        title={sections.title}
                        subtitle={sections.subtitle}
                        description={sections.description}
                        image={sections.image.childImageSharp.gatsbyImageData}
                        alt={sections.alttext}
                        placement={sections.placement}
                        titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                        link={sections.btnlink}
                        colour={sections.btncolour}
                      />
                    </div>
                  </MDBContainer>
                </FadeInWhenVisible>
              </section>
            ))}
          </>
        )}

        {resources && (
          <>
            {resources.frontmatter.section.map((sections, index) => (
              <section className="bg-white-grey-gradient" key={index}>
                <FadeInWhenVisible>
                  <MDBContainer>
                    <h2 className="font-alt font-w-700 letter-spacing-1 mb-4 pb-4 title-xs-large title-large text-left">
                      {sections.title}
                    </h2>
                    <MDBRow>
                      {sections.feature.map((features, index) => (
                        <Card
                          key={index}
                          collg="4"
                          colmd="6"
                          height="5.5rem"
                          title={features.title}
                          subtitle={features.subtitle}
                          description={features.linktext}
                          image={features.image ? features.image.childImageSharp.gatsbyImageData : null}
                          alt={features.alttext}
                          link={features.link ? features.link : features.document.publicURL}
                          titleclass="title-small"
                          descriptionClass="text-card-small"
                        />
                      ))}
                    </MDBRow>
                  </MDBContainer>
                </FadeInWhenVisible>
              </section>
            ))}
          </>
        )}

        <Cta
          ctatitle={post.frontmatter.cta.title}
          ctadescription={post.frontmatter.cta.description}
          ctalink={post.frontmatter.cta.link}
          ctalinktext={post.frontmatter.cta.linktext}
        />
      </main>
    </Layout>
  )
}
export default ServerBranding

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { name: { eq: "server-branding" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        ctatext
        ctaslug        
        section {
          title
          subtitle
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
          placement
          alttext
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
    serverbrands: allMarkdownRemark( filter: {frontmatter: {brandname: {ne: null}}}, sort: {fields: frontmatter___sequence}) {
      edges {
        node {
          frontmatter {
            brandname
            branddescription
            brandlogo {
              childImageSharp {
                gatsbyImageData(height: 50, quality: 90) 
              }
            }
            alttext
            section {
              linktext
              link
            }
          }
        }
      }
    }
    resources: markdownRemark(frontmatter: {template: {eq: "resources"}, title: {eq: "Helpful resources"}}) {
      frontmatter {
        title
        subtitle
        section {
          title
          subtitle
          placement
          feature {
            title
            subtitle
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
            alttext
            linktext
            link
            document {
              publicURL
            }
          }
        }
      }
    }    
  }
`